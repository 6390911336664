


























import { Component } from "vue-property-decorator";

import Content from "@/layouts/components/Content.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon.vue";
import Spinner from "@/components/Spinner.vue";
import TopBanner from '@/components/banners/TopBanner.vue'

import { BreakpointsMixin } from "@/mixins/breakpoints.mixin";
import NewsItem from "@/modules/NewsPage/components/NewsItem.vue";
import {NewsModel} from "@/core/models/NewsModel";
import {namespace} from "vuex-class";
import newsService from "@/core/services/NewsService";
const NewsStore = namespace("NewsStore");

@Component({
  name: "NewsPage",
  components: {
    NewsItem,
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    Spinner,
    TopBanner
  },
  metaInfo: {
    title: "Новости",
  },
})
export default class NewsPage extends BreakpointsMixin {
  loading = false;

  @NewsStore.Action
  private getNews!: () => Promise<void>;

  @NewsStore.Getter
  public newsList!: NewsModel[];

  created(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.getNews(),
    ]);
    this.loading = false;
  }
  async readAll(): Promise<void>{

    for await (const item of this.newsList) {
      await newsService.readNews(item.id)
    }

    await this.getNews()
  }

}
